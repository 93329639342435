/*
	~ STATIC - Base Foundation of All Application Styles
*/


/* Generic DOM Elements ---------- */

::-moz-selection { /* Code for Firefox */
	color: #ffffff;
	background: #ecc60eff;
}

::selection {
	color: #ffffff;
	background: #ecc60eff;
}

html, body {
	margin: 0;
	font-family: "Arial";
	color: rgba(0,0,0,0.87);
}

#root {
	flex: 1;
	height: auto;
	display: flex;
	flex-direction: column;
}

p {
	letter-spacing: 1px;
	font-family: "Arial";
}

h1, h2 {
	font-weight: 600;
}

h3, h4 {
	font-weight: 400;
}

h5 {
	font-weight: 200;
}

h1, h2, h3, h4, h5 {
	letter-spacing: 2px;
	font-family: "Arial";
}